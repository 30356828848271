$(function() {
    // 記録項目
    $(".handle-block").hover(
        function() {
            $(this).children(".move_off").hide();
            $(this).children(".move_on").show();
        }, 
        function() {
            $(this).children(".move_on").hide();
            $(this).children(".move_off").show();
        }
    );
    $(".sortable-list").sortable({
        axis: "y",
        items: ".item",
        delay: 200,
        handle: ".handle",

        update(e, ui) {
            let item = ui.item;
            let model_name = item.attr("model_name");
            let update_url = item.attr("update_url");
            let params = { _method: 'put' };
            params[model_name] = { row_order_position: item.index() };
            $.ajax({
                type: 'POST',
                url: update_url,
                dataType: 'json',
                data: params,
            });
        },

        start(e, ui) {

        },

        stop(e, ui) {
            return ui.item.children('td').effect('highlight');
        }
    });
    
    // チェックリスト
    $(".sortable-checklist").sortable({
        axis: "y",
        items: ".checklist-item",
        delay: 200,

        update(e, ui) {
            let item = ui.item;
            let model_name = item.attr("model_name");
            let update_url = item.attr("update_url");
            let params = { _method: 'put' };
            params[model_name] = { row_order_position: item.index() };
            $.ajax({
                type: 'POST',
                url: update_url,
                dataType: 'json',
                data: params,
            });
        },

        start(e, ui) {
        },

        stop(e, ui) {
            return ui.item.children('td').effect('highlight');
        }
    });

});