$(function() {
    /* Project */
    $("#new_project_form").validationEngine({
        maxErrorsPerField: 1,
        promptPosition: "inline",
    });
    /* Series Datum */
    $("#new_series_form").validationEngine({
        maxErrorsPerField: 1,
        promptPosition: "inline",
    });
    $("#new_input_item_form").validationEngine({
        maxErrorsPerField: 1,
        promptPosition: "inline",
    });
    $(".edit_input_item_form").validationEngine({
        maxErrorsPerField: 1,
        promptPosition: "inline",
    });
    /* Chart Phase */
    $("#new_chart_phase_form").validationEngine({
        maxErrorsPerField: 1,
        promptPosition: "inline",
    });
    $(".chart-phase-form").validationEngine({
        maxErrorsPerField: 1,
        promptPosition: "inline",
    });
})